// JavaScript Document
jQuery( document ).ready(function($) {
    
	$(document).foundation();
	$( "body" ).fadeTo( "slow" , 1);
	console.log('ha')
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.overlay-menu').toggleClass('is-active'); 
		$('html').toggleClass('locked');

	});
	$(window).scroll(function() {
		if ($(this).scrollTop() > 250)
		{
		$('.sticky-header').addClass("active");
		}
		else
		{
		$('.sticky-header').removeClass("active");
		}
	});
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});
	
	// Customizing a reveal set
	window.sr = ScrollReveal();
	
	sr.reveal('.rvl', {
		duration: 500,
		distance:'0px',
		scale:1,
		delay:100
		});
	
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);


	$('.clientzone .login').on('click', function(){
		$('.login-form').toggle();
	})
	$('.login-form .close-login-form').on('click', function(){
		$('.login-form').toggle();
	})

	$('#login_form input[name=username]').focus( function(){
		$('#login_form input[name=cpt]').val('425');
	});

	//LOGIN FORM
	$('#login_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {

			console.log("form is valid");
			$('#login_form .loading').css('display','inline-block');
			var that = $('#login_form'),
			url = that.attr("action"),
			method = that.attr("method"),
			data = {};
			that.find("[name]").each( function() {
				var that = $(this),
					name = that.attr("name"),
					value = that.val();
					data[name] = value;
			});
			
			if($('#login_form input[name=cpt]').val() == '425') {
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log(response);
						if(response == 'success'){
							$('.login-form .successMessage').text('U bent ingelogd, pagina wordt verversd...'); 
							$('.login-form .successMessage').show();
							$('#login_form .loading').hide();
							location.reload();
						} else if(response == 'fail'){
							$('.login-form .successMessage').text('Foute gebruikersnaam of wachtwoord, probeer nogmaals'); 
							$('.login-form .successMessage').show(); 
							$('#login_form .loading').hide();
						} else {
							$('.login-form .successMessage').text('Mislukt, probeer nogmaals'); 
							$('.login-form .successMessage').show(); 
							$('#login_form .loading').hide();
						}
						
					},
					error: function(response){
						$('#login_form .loading').hide();
						console.log(response);
						$('.login-form .successMessage').text('Error, try again'); 
						$('.login-form .successMessage').show(); 
					}
				});
			} else {
				$('#login_form .loading').hide();
				$('.login-form .successMessage').hide(); 
			}
		});
	
});
